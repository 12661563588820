@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

@font-face {
  font-family: "Bahij";
  src: url("../fonts/font-ar/Bahij_TheSansArabic-SemiBold.ttf")
    format("truetype");
  src: url("../fonts/font-ar/Bahij_TheSansArabic-Bold.ttf") format("truetype");
  src: url("../fonts/font-ar/Bahij_TheSansArabic-ExtraBold.ttf")
    format("truetype");
  src: url("../fonts/font-ar/Bahij_TheSansArabic-ExtraLight.ttf")
    format("truetype");
  src: url("../fonts/font-ar/Bahij_TheSansArabic-Plain.ttf") format("truetype");
  src: url("../fonts/font-ar/Bahij_TheSansArabic-SemiLight.ttf")
    format("truetype");

  font-weight: normal;
  font-style: normal;
}

$color: lightblue;
$light: #ffffff;
$sunsh: #f5de19;
$bottom: #6d0005;
$grass: #44882d;
$main-color: #212155;
$main-color-light: #52529a;

body {
  margin: 0;
  min-height: 100vh;
  overflow: hidden;
  font-family: "Libre Franklin", sans-serif;
  font-size: 62.5%;
  background-image: linear-gradient(0deg, lighten($color, 15%), $color);
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 900;
  color: $light;
  text-shadow: 10px 10px 50px darken($light, 10%);
  user-select: none;

  span {
    display: block;
  }
  p {
    color: #609cb0;
    opacity: .8;
    margin: 0;
}
  h1 {
    font-size: 15rem;
    line-height: normal;
    margin: -10px 0 !important;
  }
}

.sun {
  position: absolute;
  top: 4em;
  left: 4em;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: radial-gradient(circle at 50%, $light 50%, $sunsh);
  box-shadow: 0px 0px 40px $sunsh, inset 0px 0px 100px $sunsh;
  animation: sun 5s linear infinite;
}

.clouds {
  position: absolute;
  right: 0;
  bottom: 35vh;
  left: 0;
  opacity: 0.5;

  div {
    position: relative;
    left: -30%;
    width: 220px;
    height: 80px;
    border-radius: 50%;
    background-color: $light;
    background-image: radial-gradient(
      ellipse at 50%,
      transparent,
      darken($light, 1%),
      darken($light, 3%)
    );
    filter: blur(3px);
    animation: clouds 60s linear infinite, cloud 8s linear infinite;

    &::before,
    &::after {
      position: absolute;
      content: "";
      width: inherit;
      height: inherit;
      border-radius: 50%;
      background-color: darken($light, 5%);
      background-image: radial-gradient(
        ellipse at 50%,
        transparent,
        darken($light, 5%),
        darken($light, 3%)
      );
      filter: blur(3px);
      animation: cloud 3s linear infinite;
    }
    &::after {
      background-color: darken($light, 10%);
    }

    &:nth-child(2) {
      animation-delay: 5s;
    }

    &:nth-child(3) {
      animation-duration: 40s;
    }
  }
}

.birds {
  position: absolute;
  right: -10%;
  top: 30px;
  width: 10px;
  height: 10px;
  background-color: darken($bottom, 20%);
  clip-path: polygon(
    70% 59%,
    100% 54%,
    64% 68%,
    48% 83%,
    81% 86%,
    55% 90%,
    0 92%,
    19% 76%,
    0 60%,
    33% 71%
  );
  animation: birds 30s linear infinite, fly 1s linear infinite;
}

.sea {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 25vh;
  background-color: $color;
}

.wave {
  position: absolute;
  left: -25%;
  bottom: 0;
  width: 150%;
  height: 100%;
  background-color: $color;
  animation: vawe 20s linear infinite;

  &.w-1 {
    background: darken($color, 5%);
    animation-delay: 2s;
  }

  &.w-2 {
    background: darken($color, 10%);
  }
}

.bottom {
  position: absolute;
  right: -2em;
  bottom: 0;
  left: -2em;
  height: 1.35em;
  border-radius: 78% 22% 52% 48% / 100% 100% 0% 0%;
  background-color: darken($bottom, 10%);
  background-image: linear-gradient(0deg, darken($bottom, 15%), $bottom);
  opacity: 0.8;
}

.grass {
  position: absolute;
  bottom: 5px;
  left: 5em;
  width: 3px;
  height: 35px;
  background-color: $grass;
  background-image: linear-gradient(45deg, $grass, darken($grass, 15%));
  border-radius: 40%;
  transform-origin: 50% 100%;
  animation: grass 2s linear infinite;

  &:nth-child(2) {
    bottom: 3px;
    left: 30%;
  }

  &:nth-child(3) {
    bottom: 8px;
    left: 60%;
  }

  &:nth-child(4) {
    bottom: 6px;
    left: 80%;
  }

  span {
    position: absolute;
    left: -5px;
    width: 8px;
    height: 2px;
    background-color: $grass;
    background-image: inherit;
    transform: rotate(45deg);

    &::before {
      position: absolute;
      top: 4px;
      left: 4px;
      content: "";
      width: inherit;
      height: inherit;
      background-color: inherit;
      transform: rotate(-90deg) translateX(100%);
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 15px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }
}
.circle {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: 25vh;

  span {
    position: absolute;
    bottom: 0;
    left: 12%;
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $light;
    opacity: 0.2;
    animation: circle 2s linear infinite;

    &::before,
    &::after {
      position: absolute;
      bottom: 0;
      left: 10px;
      content: "";
      width: 5px;
      height: 5px;
      border-radius: inherit;
      background-color: inherit;
      animation: circle 4s linear infinite;
      animation-delay: 0.3s;
    }

    &::after {
      right: -10px;
      animation-delay: 0.5s;
    }

    &:nth-child(2) {
      left: 25%;
      animation-delay: 0.6s;
    }

    &:nth-child(3) {
      left: 45%;
      animation-delay: 0.8s;
    }

    &:nth-child(4) {
      left: 53%;
    }

    &:nth-child(5) {
      left: 74%;
    }
  }
}

.grass_tw {
  position: absolute;
  bottom: 11px;
  left: 50%;
  width: 30px;
  height: 10px;
  background-color: $grass;
  background-image: linear-gradient(180deg, $grass, darken($grass, 20%));
  clip-path: polygon(
    10% 84%,
    14% 73%,
    17% 24%,
    20% 61%,
    22% 88%,
    32% 15%,
    31% 68%,
    35% 88%,
    49% 11%,
    63% 85%,
    69% 10%,
    77% 80%,
    84% 16%,
    89% 92%,
    97% 41%,
    100% 97%,
    50% 96%,
    0 100%,
    4% 34%
  );
  animation: grass_tw 2s linear infinite;

  &:nth-last-child(2) {
    left: 75%;
    width: 80px;
  }

  &:last-child {
    bottom: 8px;
    left: 20%;
    width: 50px;
  }
}
@keyframes sun {
  50% {
    box-shadow: 0px 0px 60px 20px $sunsh, inset 0px 0px 100px $sunsh;
  }
}

@keyframes clouds {
  0% {
    left: -30%;
  }
  100% {
    left: 110%;
  }
}

@keyframes cloud {
  30% {
    border-radius: 22% 78% 51% 49% / 78% 66% 34% 22%;
  }
  40% {
    width: 180px;
  }
  60% {
    border-radius: 61% 39% 51% 49% / 50% 66% 34% 50%;
  }
}

@keyframes birds {
  100% {
    right: 110%;
    transform: scale(1.8) translateY(100px);
  }
}

@keyframes fly {
  50% {
    clip-path: polygon(
      70% 69%,
      97% 80%,
      70% 76%,
      48% 83%,
      81% 86%,
      55% 90%,
      0 92%,
      19% 76%,
      0 71%,
      33% 71%
    );
  }
}

@keyframes fish {
  0% {
    left: 110%;
  }
  40% {
    transform: translateY(35px) rotate(-15deg);
  }
  70% {
    transform: translateY(-5px) rotate(15deg);
  }
  80% {
    transform: translateY(5px) rotate(0deg);
  }
  100% {
    left: -10%;
  }
}

@keyframes circle {
  0% {
    transform: translateY(0px) scale(0);
  }
  50%,
  80% {
    transform: translateY(-25px) scale(1);
  }
  100% {
    transform: translateY(-30px) scale(0);
    opacity: 0;
  }
}

@keyframes side {
  50% {
    transform: rotate(-35deg);
  }
}

@keyframes vawe {
  20%,
  40% {
    border-radius: 23% 77% 52% 48% / 100% 100% 0% 0%;
  }
  30%,
  50%,
  70% {
    border-radius: 37% 63% 100% 0% / 100% 100% 0% 0%;
  }
  80%,
  60% {
    border-radius: 78% 22% 52% 48% / 100% 100% 0% 0%;
  }
}

@keyframes grass {
  50% {
    transform: rotate(5deg);
  }
}

@keyframes grass_tw {
  50% {
    clip-path: polygon(
      10% 84%,
      14% 73%,
      10% 28%,
      20% 61%,
      22% 88%,
      24% 18%,
      31% 68%,
      35% 88%,
      42% 15%,
      63% 85%,
      58% 14%,
      77% 80%,
      77% 18%,
      89% 92%,
      91% 43%,
      100% 97%,
      50% 96%,
      0 100%,
      3% 39%
    );
  }
}

.sweep-to-right-hover {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  &:hover {
    &:before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}

.main-btn {
  font-size: 18px;
  letter-spacing: 0.8px;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  padding: 1.094rem 2.05rem;
  min-width: 150px;
  width: max-content;

  &.dark-btn {
    background-color: $main-color-light;

    &.sweep-to-right-hover::before {
      background-color: $main-color;
    }

    &:hover {
      color: #fff;
    }
  }
}

@media (max-width: 576px) {
  .sea,
  .bottom {
    display: none;
  }
  .error h1 {
    font-size: 10rem;
}
}


html[dir="rtl"] {
  .main-btn {
    letter-spacing: 0;
  }  
}